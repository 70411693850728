'use client';

import clsx from 'clsx';
import { type RefObject, useEffect, useRef, useState } from 'react';
import { VisuallyHidden } from 'react-aria';
import {
  Button,
  Collection,
  ComboBox as ComboBoxPrimitive,
  type ComboBoxProps,
  ListBox,
  Popover as PopoverPrimitive,
  Text,
} from 'react-aria-components';
import { useAsyncList } from 'react-stately';

import { QUERY_SUGGESTIONS_INDEX } from '@/lib/algolia/indices';

import {
  ListBoxHeader,
  ListBoxItem,
  ListBoxSection,
} from '@/components/combo-box/combo-box';
import { Icon } from '@/components/icon/icon';

import { useRecentSearches } from '../header-search/recent-searches';

interface QuerySuggestion {
  query: string;
}

async function getAlgoliaClient() {
  const mod = await import('@/lib/algolia/client');
  return mod.configuredAlgoliaSearchClient;
}

function timeoutWithSignal(ms: number, signal: AbortSignal) {
  return new Promise<void>((resolve, reject) => {
    const id = setTimeout(() => {
      resolve();
    }, ms);

    signal.addEventListener('abort', () => {
      clearTimeout(id);
      reject(new Error('Aborted'));
    });
  });
}

function Popover({
  children,
  className,
  triggerRef,
}: {
  children: React.ReactNode;
  className?: string;
  triggerRef: RefObject<HTMLDivElement>;
}) {
  const [width, setWidth] = useState<undefined | number>(undefined);

  useEffect(() => {
    if (triggerRef?.current) {
      setWidth(triggerRef.current.clientWidth);
    }
  }, [triggerRef]);

  return (
    <PopoverPrimitive
      triggerRef={triggerRef}
      placement="bottom left"
      isNonModal
      shouldFlip={false}
      className={clsx(
        className,
        'overflow-auto rounded-lg bg-white shadow-md focus:outline-none focus:ring'
      )}
      style={{
        '--anchor-width': `${width}px`,
      }}
    >
      {children}
    </PopoverPrimitive>
  );
}

export function ComboBox<T extends object>({
  comboBoxRef,
  isRequired,
  children,
  allowsCustomValue,
  items,
  inputValue,
  onInputChange,
  ...rest
}: {
  comboBoxRef?: RefObject<HTMLDivElement>;
  isRequired?: boolean;
  allowsCustomValue?: boolean;
  children: React.ReactNode;
  items?: ComboBoxProps<T>['items'];
  inputValue?: ComboBoxProps<T>['inputValue'];
  onInputChange?: ComboBoxProps<T>['onInputChange'];
}) {
  return (
    <div ref={comboBoxRef}>
      <ComboBoxPrimitive
        menuTrigger="focus"
        isRequired={isRequired}
        className="flex flex-col gap-0.5"
        allowsCustomValue={allowsCustomValue}
        items={items}
        inputValue={inputValue}
        onInputChange={onInputChange}
        aria-label="Search for a country or region"
        {...rest}
      >
        {children}
      </ComboBoxPrimitive>
    </div>
  );
}

export function SearchQuery({ children }: { children: React.ReactNode }) {
  const { recentSearches, removeRecentSearch, clearRecentSearches } =
    useRecentSearches();

  const popular = useAsyncList<QuerySuggestion>({
    async load({ filterText, signal }) {
      if (!filterText) {
        // @FIXME: We should load popular searchs on focus
        return { items: [] };
      }

      const client = await getAlgoliaClient();

      // Wait 150ms before doing search. Will cancel search if the signal is aborted.
      await timeoutWithSignal(150, signal);

      const result = await client.searchForHits<QuerySuggestion>({
        requests: [
          {
            indexName: QUERY_SUGGESTIONS_INDEX,
            query: filterText ?? '',
            hitsPerPage: 5,
          },
        ],
      });

      return {
        items: result.results[0].hits.map((hit) => {
          return {
            query: hit.query,
          };
        }),
      };
    },
  });

  const recentSearchesItems = recentSearches.map((search) => ({
    name: search,
  }));

  const ref = useRef<HTMLDivElement>(null!);

  return (
    <ComboBox
      comboBoxRef={ref}
      allowsCustomValue
      onInputChange={popular.setFilterText}
      inputValue={popular.filterText}
    >
      {children}
      <VisuallyHidden>
        {/** Open trigger must be present, else the popover doesn't close properly */}
        <Button>Open list</Button>
      </VisuallyHidden>
      <Popover
        triggerRef={ref}
        className="w-[var(--anchor-width)] md:w-[500px]"
      >
        <ListBox className="flex flex-col gap-4 p-6">
          {recentSearchesItems.length > 0 ? (
            <ListBoxSection>
              <ListBoxHeader className="flex items-baseline justify-between">
                Recent searches{' '}
                <button
                  className="text-cap-sm underline hover:no-underline"
                  onClick={() => {
                    clearRecentSearches();
                  }}
                >
                  Clear all
                </button>
              </ListBoxHeader>
              <Collection items={recentSearchesItems}>
                {(item) => {
                  return (
                    <ListBoxItem id={item.name} textValue={item.name}>
                      <Icon iconName="clock" />
                      <Text slot="label">{item.name}</Text>
                      <button
                        className="ml-auto"
                        aria-label="Remove from recent search"
                        onPointerUp={(e) => {
                          e.stopPropagation();

                          removeRecentSearch(item.name);
                        }}
                      >
                        <Icon iconName="x" />
                      </button>
                    </ListBoxItem>
                  );
                }}
              </Collection>
            </ListBoxSection>
          ) : null}

          <ListBoxSection>
            <ListBoxHeader>Popular searches</ListBoxHeader>
            <Collection items={popular.items}>
              {(item) => {
                return (
                  <ListBoxItem id={item.query + 'more'} textValue={item.query}>
                    <Icon iconName="magnifying-glass" />
                    <Text slot="label">{item.query}</Text>
                    <button
                      className="ml-auto"
                      aria-label="Remove from recent search"
                      onPointerUp={(e) => {
                        e.stopPropagation();
                        popular.setFilterText(item.query);
                      }}
                    >
                      <Icon iconName="arrow-up-left" />
                    </button>
                  </ListBoxItem>
                );
              }}
            </Collection>
          </ListBoxSection>
        </ListBox>
      </Popover>
    </ComboBox>
  );
}
